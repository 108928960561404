<template>
  <v-container fluid>
    <search-form @submit="handleSearchFormSubmit"/>
    <v-card class="mt-5">
      <v-card-title>
        <v-icon
          small
          color="white"
          class="elevation-6 pink lighten-1 pa-2 mr-3"
        >
          mdi-format-list-bulleted-square
        </v-icon>
        {{$vuetify.lang.t('$vuetify.role.list')}}
        <v-spacer></v-spacer>

        <v-btn
          class="mx-2"
          color="primary"
          fab
          dark
          x-small
          @click="handleAddClick"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="roles"
          item-key="name"
          hide-default-footer
        >
          <template v-slot:item.dep="{ item }">
            {{item.department.showName}}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              class="mr-2"
              color="info"
              small
              @click="handleEditItem(item)"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              color="error"
              small
              @click="handleDeleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
        <v-pagination
          v-model="queryParams.params.page"
          :length="queryParams.params.pageCount"
        ></v-pagination>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="dialogBaseForm"
      persistent
      max-width="800px"
    >
      <base-form
        ref="baseForm"
        :edit-status="editStatus"
        :edit-item="editItem"
        @submit="handleBaseFormSubmit"
        @update="handelBaseFormUpdate"
        @cancel="handleBaseFormCancel"
      />
    </v-dialog>
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          警告
        </v-card-title>
        <v-card-text v-if="editItem">
          确定要删除
          <Strong>
            {{ editItem.showName }}
          </Strong>
          ?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="info darken-1"
            @click="deleteDialog = false"
          >
            取消
          </v-btn>
          <v-btn
            color="error darken-1"
            @click="handleDeleteDialog"
          >
            删除
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex';

  export default {
    components: {
      SearchForm: () => import('./SearchForm'),
      BaseForm: () => import('./BaseForm')
    },
    data: () => ({
      userItems: [],
      queryParams: {
        params: {
          page: 1,
          pageCount: 1
        },
        data: {}
      },
      dialogBaseForm: false,
      editStatus: false,
      editItem: null,
      deleteDialog: false
    }),

    computed: {
      ...mapGetters({
        roles: 'role/roles'
      }),
      headers () {
        return [
          {
            text: this.$vuetify.lang.t('$vuetify.role.roleName'),
            align: 'start',
            sortable: false,
            value: 'roleName',
          },{
            text: this.$vuetify.lang.t('$vuetify.role.showName'),
            align: 'start',
            sortable: false,
            value: 'showName',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.description'),
            align: 'start',
            sortable: false,
            value: 'description',
          },{
            text: this.$vuetify.lang.t('$vuetify.common.actions'),
            align: 'start',
            sortable: false,
            value: 'actions',
          }
        ]
      }
    },
    watch: {
      'queryParams.params.page': {
         handler: function(newValue) {
            let params = Object.assign({}, this.queryParams.params)
            params.page = newValue - 1
            this.roleQuery({params: params, data: this.queryParams.data }).then( (res) => {
              this.queryParams.params.pageCount = res.totalPages
            })
         }
      }
    },
    created () {
      let params = Object.assign({}, this.queryParams.params)
      params.page--
      this.roleQuery({
        params: params,
        data: {}
      }).then( (res) => {
        this.queryParams.params.pageCount = res.totalPages
      })
    },

    methods: {
      ...mapActions({
        roleQuery: 'role/query',
        roleCreate: 'role/create',
        roleUpdate: 'role/update',
        roleDelete: 'role/delete'
      }),
      handleSearchFormSubmit(data) {
        this.queryParams.data = data
        let params = Object.assign({}, this.queryParams.params)
        params.page = 0
        this.roleQuery({params: params, data }).then( (res) => {
          this.queryParams.params.pageCount = res.totalPages
        })
      },
      handleAddClick() {
        this.editStatus = false
        this.dialogBaseForm = true
        this.$nextTick( () => {
          this.$refs.baseForm.initialize()
        })

      },
      handleBaseFormSubmit(data) {
        this.roleCreate(data).then(() => {
          this.dialogBaseForm = false
        })
      },
      handelBaseFormUpdate(data) {
        this.roleUpdate(data).then(() => {
          this.dialogBaseForm = false
        })
      },
      handleBaseFormCancel() {
        this.dialogBaseForm = false
      },
      handleEditItem (item) {
        this.editStatus = true
        this.editItem = item
        this.dialogBaseForm = true
        this.$nextTick( () => {
          this.$refs.baseForm.initialize()
        })
      },
      handleDeleteItem (item) {
        this.editItem = item
        this.deleteDialog = true
      },
      handleDeleteDialog() {
        this.roleDelete(this.editItem).then( () => {
          this.deleteDialog = false
        })
      }
    },
  }
</script>
